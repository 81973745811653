import React from "react";
import { Col, Row } from "react-bootstrap";

import { Translation } from "../models";

interface Props {
  items: Translation[];
}

const MenuItems = ({ items }: Props) => {
  return (
    <Row>
      {items.map((item, index) => (
        <Col lg={6} key={index} className="menuItem">
          <h4>{item.title}</h4>
          <div
            className="price"
            dangerouslySetInnerHTML={{
              __html: `${item.content}`,
            }}
          />
          <div className="dottedBg" />
        </Col>
      ))}
    </Row>
  );
};

export default MenuItems;
