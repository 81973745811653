import { Col, Container, Row } from "react-bootstrap";
import React, { useContext, useState } from "react";
import { PageProps, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import LangContext, { Labels } from "../context/LangContext";
import { AllDirectusPhiloContent, DirectusPhiloContent, Menu, Pages, Translation } from "../models";
import MenuItems from "../components/menuItems";

interface Props {
  site: {
    siteMetadata: {
      pages: Pages;
      menu: Menu;
    };
  };
}

const renderCategories = (
  menu: Menu,
  lang: keyof Labels,
  selected: keyof Menu,
  onClick: (c: keyof Menu) => void
) =>
  Object.keys(menu).map((t: keyof Menu) => (
    <span key={t} onClick={() => onClick(t)} className={t === selected ? "active" : ""}>
      {menu[t][lang]}
    </span>
  ));

const filterLangItems = (lang: string) => (n: DirectusPhiloContent) => {
  const t = n.translations.find(t => t.language === lang);
  return t ? t : n.translations.find(t => t.language === "fr");
};

const CarteMenu: React.FC<PageProps<AllDirectusPhiloContent & Props>> = ({ data }) => {
  const ctx = useContext(LangContext);
  const [category, setCategory] = useState<keyof Menu>("ALL");
  const [items, setItems] = useState<Translation[]>([]);
  const title = data.site.siteMetadata.pages.allMenu[ctx.lang];

  React.useEffect(() => {
    setItems(data.allDirectusPhiloContent.nodes.map(filterLangItems(ctx.lang)));
  }, [data.allDirectusPhiloContent.nodes, ctx.lang]);

  return (
    <Layout>
      <SEO title={title} />
      <div className="allMenu page-container">
        <h1>{title}</h1>
        <Container fluid={true}>
          <Row className="menuTags">
            <Col lg={12} className="categories">
              {renderCategories(data.site.siteMetadata.menu, ctx.lang, category, setCategory)}
            </Col>
          </Row>
          <Row>
            <Col lg={8} className="itemsBlock">
              <MenuItems items={items.filter(i => i.category === category || category === "ALL")} />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};
// prettier-ignore
export const query = graphql`
  {
    allDirectusPhiloContent(filter: { page: { eq: "menu" } }) {
      nodes {
        name page order
        translations {
          language title subtitle category content
          illustration {
            data {
              full_url
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        pages {
          allMenu {
            fr de it en
          }
        }
        menu {
          ALL {
            fr de en it
          }
          ENTREE_FROIDE {
            fr de en it
          }
          ENTREE_CHAUDE {
            fr de en it
          }
          POTAGE {
            fr de en it
          }
          ENFANT {
            fr de en it
          }
          PATE {
            fr de en it
          }
          VIANDE {
            fr de en it
          }
          POISSON {
            fr de en it
          }
          PETITE_RESTAURATION {
            fr de en it
          }
        }
      }
    }
  }
`;

export default CarteMenu;
